import React from 'react';
import './App.css';
import {UrlForm} from "./UrlForm";
import {Footer} from "./Footer"
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Minecraft News</h1>
        <p>This tool will allow you to register a Discord or custom webhook to receive the latest Minecraft Articles</p>
        <p>When a new article is published, the URL will receive a <code>POST</code> request with <code>JSON</code> information (test and preview available in the next step)</p>
        <p>If using a custom webhook, I recommend you add an argument containing a unique code (e.g. <code>https://mysite.com/news?code=mysecretcode</code>). This will ensure only you can manage the webhook and that all requests are legitimate.</p>
          <p>You must respond with <a href={"https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/204"}>204 No Content</a> upon receiving the request. If this is not met, 10 subsequent requests will be sent in 1 minute intervals until the correct status code is received. If your webhook fails to respond successfully to 5 new articles, your webhook will be deleted.</p>
        <UrlForm
            onSubmit={({ url }) => {
                alert("Registering webhooks is disabled. Check back later!");
            }}
        />
      </header>
        <div className="footer">
            <Footer/>
        </div>

    </div>
  );
}

export default App;
