import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {Form, Formik, Field, useFormik} from "formik";
import * as yup from 'yup';
import {Box, Grid, Skeleton, TextField} from "@mui/material";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import React from "react";

const validationSchema = yup.object({
    url: yup
        .string()
        .max(160, 'Max URL length is 160 characters')
        .url('Must be a valid URL')
        .required('A URL is required')
});

interface Values {
    url: string;
}

interface Props {
    onSubmit: (values: Values) => void;
}


const handleVerificationSuccess = (token: string, ekey: string, setDisabled: React.Dispatch<React.SetStateAction<boolean>>) => {
    console.log("OVER HERE")
    console.log(token)
    setDisabled(false)
}


export const UrlForm = (props: Props) => {
    const [loading, setLoading] = React.useState(true);
    const [disabled, setDisabled] = React.useState(true);
    const captchaRef = React.useRef(null);
    const formik = useFormik({
        initialValues: {
            url: '',
        },
        validationSchema: validationSchema,
        onSubmit: props.onSubmit,
    });

    //handleVerificationSuccess("k", "k", setDisabled)

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    fullWidth
                    required
                    id="url"
                    name="url"
                    label="Webhook URL"
                    variant="outlined"
                    color="primary"
                    value={formik.values.url}
                    onChange={formik.handleChange}
                    error={formik.touched.url && Boolean(formik.errors.url)}
                    helperText={formik.touched.url && formik.errors.url}
                />
                <Button color="primary" variant="contained" fullWidth type="submit" style={{ marginTop: ".5rem"}} disabled={disabled}>
                    Continue
                </Button>
                <div style={{textAlign: "center", marginTop: ".5rem", display:"flex", justifyContent:"center", alignContent: "center"}}>
                    <div style={{width:"300px", textAlign: "center", marginTop: ".5rem", display:"flex", justifyContent:"center", alignContent: "center"}}>

                        {loading ? (<Skeleton variant="rectangular" width={303} height={75} animation={"wave"} />) : (<p></p>)}
                        <HCaptcha
                            sitekey="b79c608f-4ace-42da-adf2-fb53b0bfbf3f"
                            onLoad={() => setLoading(false)}
                            onVerify={(token,ekey) => handleVerificationSuccess(token, ekey, setDisabled)}
                            onExpire={() => setDisabled(true)}
                            ref={captchaRef}
                        />

                    </div>

                </div>
            </form>
        </div>
    );
}