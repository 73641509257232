import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import {IconButton} from "@mui/material";

export const Footer = () => {
    return (
        <div style={{}}>
            <div style={{display: "flex", alignContent: "center", justifyContent: "center"}}>
                <IconButton onClick={() => {window.open("https://github.com/Ben754444")}}>
                    <GitHubIcon/>
                </IconButton>
                <IconButton onClick={() => {window.open("https://twitter.com/Ben754444")}}>
                    <TwitterIcon/>
                </IconButton>
            </div>
            <p>d9dd8ff/master - {window.location.hostname}</p>
        </div> //this gets replaced when deployed, ignore it for now
    )
}